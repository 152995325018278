/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Service from 'components/cards/service';
import icon4 from 'lottie/56369-ux-designer.json';
import icon6 from 'lottie/71676-ui-and-ux-design-isometric-animation.json';
import icon5 from 'lottie/82477-sontrol-panel.json';
import icon7 from 'lottie/78253-custom-database.json';
import icon8 from 'lottie/72259-team.json';
import icon9 from 'lottie/28784-businessmen-at-the-table.json';

const data = [
  {
    id: 1,
    icon: icon4,
    title: 'Product Design',
    description: `Our mission is to provide the application and website design that will not only just look great. We believe that well-done, data-driven design needs to also meet the business requirements of every project to maximize its ROI. Our process focuses on providing customer-friendly projects that will also optimize KPIs such as conversion rate.`,
  },
  {
    id: 2,
    icon: icon5,
    title: 'Web applications',
    description: `We use modern tools and frameworks that allow us to create web applications with short time-to-market, great performance and low costs. We always provide accurate estimates for the project-related costs – and we have never exceeded the project budget that we agreed upon before starting our cooperation.`,
  },
  {
    id: 3,
    icon: icon6,
    title: 'Mobile applications',
    description: `By using cross-platform technologies and supporting them with native code (Kotlin/Swift + React Native) we can deliver mobile applications for every platform that has a fully native User Experience. This way, we can be sure that we deliver top-quality solutions for every project.`,
  },
  {
    id: 4,
    icon: icon8,
    title: 'Outsourcing services',
    description: `Our outsourcing services aren’t just based on providing developers for your project. We always assign a free-of-charge Relationship Manager that will be responsible for covering all your other needs and communication with our team. Even if the scope of our cooperation changes, we are able to always find a solution for your project.`,
  },
  {
    id: 5,
    icon: icon7,
    title: 'DevOps & Architecture',
    description: `Wherever you need a standard industry architecture or a “made-to-fit” pipeline - we can deliver it. We can take care of the whole development process if you like!`,
  },
  {
    id: 6,
    icon: icon9,
    title: 'Startup Consulting',
    description: `iMakeable offers a complex startup building service from the stage of idea to scaling-up your business. We minimize the risk of failure with maximum effectiveness. You get support from the very beginning of any idea – including fundraising, business analysis, team building, software development, marketing & sales and scaling up your product. Together with our experienced Venture Capital / Private Equity experts, we can also evaluate your project.`,
  },
];

const OtherServices = () => {
  return (
    <Box as="section" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="Services dedicated for your project"
          description="We believe that in the perfect world, clients we work with should be focused on business side and scaling up their projects. As iMakeable, we want to help you with all the other sides of your startup that we can."
        />
        <Box sx={styles.contentWrapper}>
          {data?.map((item) => (
            <Service key={item.id} item={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};
export default OtherServices;

const styles = {
  section: {
    backgroundColor: '#F9FAFC',
    pt: [9, 9, 9, 11],
    pb: [9, 9, 9, 12, 12, 14],
  },
  heading: {
    mb: [6, null, null, 8, 9, null, 13],
    p: {
      maxWidth: 500,
      margin: '10px auto 0',
    },
  },
  contentWrapper: {
    gap: ['30px 30px', '30px 30px', '30px 30px', '80px 30px'],
    display: 'grid',
    justifyContent: ['center', 'center', 'center', 'unset'],
    gridTemplateColumns: [
      'repeat(1, 285px)',
      'repeat(1, 325px)',
      'repeat(1, 285px)',
      'repeat(3, 1fr)',
    ],
  },
};
