/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { rgba } from 'polished';
import SectionHeading from 'components/section-heading';
import Service from 'components/cards/service';
import icon1 from 'lottie/72927-social-media.json';
import icon2 from 'lottie/77984-money-loading.json';
import icon3 from 'lottie/8517-charts.json';

const data = [
  {
    id: 1,
    icon: icon1,
    title: 'MVP and Startup Development',
    description: `Besides standard Software Development, Product Design and QA Services, we also provide Business Analysis and consulting for your project. It’s in our mutual interest to make sure that your project will be a success – and we do everything we can to make that happen.`,
  },
  {
    id: 3,
    icon: icon2,
    title: 'Custom software solutions',
    description: `We have delivered dozens of large-scale projects – including marketplaces, e-commerce solutions and other unpopular or fully custom software solutions. We have never left our clients unsatisfied and were able to find a solution even if the project required highly sophisticated solutions.`,
  },
  {
    id: 4,
    icon: icon3,
    title: 'Gaining revenue',
    description: `We have many years of experience in building web and mobile applications for startups that have returned their build cost in less than a year. Our custom software solutions optimize everyday work of dozens of SMEs – coming from HR, production, and e-commerce industries.`,
  },
];

const Services = () => {
  return (
    <Box as="section" id="services" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          sxDescription3={{
            fontWeight: 'bold'
          }}
          title="Convert your idea to a profitable project"
          description="Our work in iMakeable is strictly focused on “made-to-fit” web and mobile solutions that are expected to generate profits and revenue as soon as possible."
          description2="We know the largest problems of every startup founder – and we can solve them for you. iMakeable prioritizes short time-to-market and Product Design to allow you to generate income with top quality applications."
          description3="We’re not just another software development company."
          description4="iMakeable offers complex startup development service that includes Business Analysis, Product Design, Software Development and Marketing services to make sure that your project will be a success.
          We have learned that providing just MVP Development is often not enough – and we have the perfect solution."
        />
        <Box sx={styles.contentWrapper}>
          {data?.map((item) => (
            <Service key={item.id} item={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  section: {
    backgroundColor: rgba('#FFF5ED', 0.5),
    pt: [11, 11, 11, 12, 12, 12, 14],
    pb: [7, 7, 7, 9, 9, 10, 11],
  },
  heading: {
    maxWidth: [null, null, null, 455, 660],
    mb: [6, null, null, 8, null, 9, 13],
  },
  contentWrapper: {
    gap: 30,
    display: 'grid',
    justifyContent: ['center', null, null, 'unset'],
    gridTemplateColumns: [
      'repeat(1, 285px)',
      'repeat(1, 325px)',
      'repeat(1, 285px)',
      'repeat(3, 1fr)',
    ],
  },
};
