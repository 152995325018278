/** @jsx jsx */
import { jsx, Box, Container, Image, Text } from 'theme-ui';
import Tabs, { TabPane } from 'rc-tabs';
import { rgba } from 'polished';
import quote from 'assets/images/icons/quote.png';
import gispartner from 'assets/images/logos/gis.png';
import mark from 'assets/images/logos/mark.png';
import wp from 'assets/images/logos/wp.png';
import staff from 'assets/images/logos/3staff.png';
import poznaj from 'assets/images/logos/poznaj.png';
import rkkvc from 'assets/images/logos/RKKVC.png';
import dermatolog from 'assets/images/logos/dermatolog.png';

const data = [
  {
    id: 1,
    logo: gispartner,
    author: 'GISPartner – leading geographic information systems supplier in Poland – support with custom solution for Geographic Information Systems industry',
    quote: `"They did what we wanted in a way making a project easier for us, for a reasonable price. Thanks to iMakeable's work, their contribution led to the on-time delivery of a complex solution that included integration with a large amount of data. The team communicated seamlessly and was always available. Above all, they impress with their willingness to find satisfying solutions."`,
  },
  {
    id: 2,
    logo: mark,
    author: 'Mark Whittey – Apogee UK Management Ltd – custom social media platform for football players',
    quote: `"iMakeable team is very efficient - they carefully speak to you about your needs so that they clear and then execute your request with precision - very impressive. Communication was on point, and they constantly kept me updated and remained in touch... I will definitely"`,
  },
  {
    id: 3,
    logo: wp,
    author: '"Warsaw Players – custom social platform for boutique tennis club in Warsaw"',
    quote: `“We have tried to build our social platform for a long time until we met iMakeable – we are really amazed with the quality of their work. Our dream social app is now a living product that is used by all of our clients”`,
  },
  {
    id: 4,
    logo: staff,
    author: 'Skilled Staff Solutions – time management & HR web and mobile app',
    quote: `"By working with iMakeable we have managed to cut our employees’ work hours by almost 30% - suddenly, the work in our HR department became easier and we have more time to work on other projects."`,
  },
  {
    id: 5,
    logo: poznaj,
    author: 'Poznajdealera.pl – Car dealership platform in Poland',
    quote: `“iMakeable develops new features for our platform for over half an year."`,
  },
  {
    id: 6,
    logo: rkkvc,
    author: 'RKKVC - Venture Capital',
    quote: `"The company overreached our expectations for the proposed price. iMakeable responsively and swiftly incorporated all the client’s needs, delivering creative and top-notch UX designs. They efficiently streamlined each process, keeping in contact with the client even during post-completion."`,
  },
  {
    id: 7,
    logo: dermatolog,
    author: 'e-dermatolog.pl - Healthcare/e-commerce',
    quote: `"They were open-minded, ambitious, hard-working people who can offer the ideal solution for any given task. They were open-minded, dedicated, and determined. I decided to begin cooperation with iMakeable, because, contrary to other software companies, they could show me straightaway reasonable and reliable solutions, the most important they provided as promised!`,
  },
];

const Testimonials = () => {
  return (
    <Box as="section" id="testimonials" sx={styles.section}>
      <Container>
        <Tabs
          sx={styles.tabs}
          animated={{ tabPane: true }}
          tabPosition="bottom"
        >
          {data?.map((item) => (
            <TabPane key={item.id} tab={<Image src={item.logo} style={{maxHeight: 64}} alt="logo" />}>
              <Box as="blockquote">
                {item.quote}
                <Text as="span" sx={styles.author}>
                  {item.author}
                </Text>
              </Box>
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </Box>
  );
};

export default Testimonials;

const styles = {
  section: {
    backgroundColor: rgba('#FFF5ED', 0.5),
    pt: [7, null, null, 9, null, 10, 11],
    pb: [9, null, null, 10, 11],
  },
  tabs: {
    border: 0,
    flexDirection: ['column-reverse', null, null, null, null, 'column'],
    '.rc-tabs-nav': {
      mt: [8, null, null, 9, 11],
    },
    '.rc-tabs-nav-wrap': {
      borderTop: `1px solid ${rgba('#01070D', 0.1)}`,
      justifyContent: 'center',
    },
    '.rc-tabs-tab': {
      backgroundColor: 'transparent',
      // m: ['0 45px'],
    },
    '.rc-tabs-tab-btn': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
      outline: 0,
      img: {
        outline: 0,
        maxWidth: [50, 65, null, 110, '80%', '100%'],
        m: ['0 auto'],
      },
    },
    '.rc-tabs-nav-list': {
      flexGrow: 1,
      justifyContent: 'space-evenly',
      pt: [4, null, null, 7, 9],
    },
    '.rc-tabs-tabpane': {
      outline: 0,
      blockquote: {
        fontFamily: 'heading',
        fontWeight: 400,
        fontSize: [2, null, null, 3, 4, 6],
        lineHeight: [1.87, null, null, 2.08],
        position: 'relative',
        maxWidth: 846,
        margin: '0px auto',
        pt: ['12px', null, null, '17px', '13px'],
        pl: [35, 35, 35, 10, 11],
        ':before': {
          background: `url(${quote}) no-repeat`,
          content: `''`,
          position: 'absolute',
          width: 128,
          height: 43,
          left: ['-31px', null, null, '-16px', '-7px', 0],
          top: ['1px', '1px', '1px', 0],
          backgroundSize: ['75%', null, null, '100%'],
        },
      },
      span: {
        color: '#7E8896',
        fontFamily: 'body',
        display: 'flex',
        fontWeight: 500,
        fontSize: [0, 1, 1, 2],
        lineHeight: 2.5,
        mt: [1, null, null, 3],
      },
    },
    '.rc-tabs-ink-bar': {
      top: 0,
      height: 2,
      backgroundColor: '#A17857',
      borderRadius: 5,
    },
  },
};
