/** @jsx jsx */
import { jsx, Box, Container, Heading, Text, Image } from 'theme-ui';
import Tabs, { TabPane } from 'rc-tabs';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { rgba } from 'polished';
import { LearnMore } from 'components/link';
import tabImage1 from 'assets/images/portfolio/tacho.png';
import tabImage2 from 'assets/images/portfolio/football.png';
import tabImage3 from 'assets/images/portfolio/wp3.png';

const data = [
  {
    id: 1,
    tabTitle: 'TachoActive',
    title: `Mobile app integrated with tachograph card readers`,
    description: `TachoActive is a mobile application that was developed for one of our clients from the transport industry that wanted to change their core service process to scale his business to other cities in a fully-remote way. We have built a custom Android app that integrates with every popular tachograph card reader on market via USB that automatically sends the read driver card data to clients’ accounting department. In this way, we have limited the necessary visits of drivers during the COVID-19 pandemic to the minimum extent possible, simultaneously giving access to our client to scale his business to other cities in Poland – fully remotely, without even meeting his new clients!`,
    image: tabImage1,
    list: [
      '#mobile',
      '#react-native',
      '#android',
      '#ios',
    ],
  },
  {
    id: 2,
    tabTitle: 'Football App',
    title: `The app allows its users to build their social presence within the app`,
    description: `Football App is a social app designed for UK soccer players with gamification elements that allow its users to connect with each other, create clubs, schedule soccer games and it offers various additional features developed solely for the purpose of this specific project to allow its users to build their social presence within the app.`,
    image: tabImage2,
    list: [
      '#social-media',
      '#react-native',
      '#mobile',
      '#multi-platform',
    ],
  },
  {
    id: 3,
    tabTitle: 'Warsaw Players',
    title: `A platform which has over 80 daily users that schedule over 600 tennis matches every month`,
    description: `Warsaw Players is a social platform built to fit the needs of exclusive tennis club located in Warsaw, Poland. We authored the gamification elements for its users that include custom tournament options, tennis league leaderboard and “quick match” feature that allows the users to schedule matches with themselves.`,
    image: tabImage3,
    list: [
      '#social-media',
      '#pwa',
      '#web',
      '#next.js',
    ],
  },
];

const WhyUs = () => {
  return (
    <Box as="section" id="why-us" sx={styles.section}>
      <Container>
        <Tabs sx={styles.tabs} animated={{ tabPane: true }}>
          {data?.map((item) => (
            <TabPane
              key={item.id}
              tab={<Heading as="h4">{item.tabTitle}</Heading>}
            >
              <Box>
                <Heading>{item.title}</Heading>
                <Text as="p" sx={styles.description}>
                  {item.description}
                </Text>
                <Box sx={styles.list}>
                  {item.list.map((item, i) => (
                    <Box key={i} className="list-item">
                      <RiCheckboxCircleFill
                        color="#3FDBB1"
                        size="20px"
                        sx={{ mr: 2 }}
                      />
                      <span>{item}</span>
                    </Box>
                  ))}
                </Box>
                {item.moreLink ? (
 <Box sx={styles.learnMore}>
 <LearnMore path={item.moreLink} label="Explore more" />
</Box>
                ) : null}
              </Box>
              <Box sx={styles.illustration}>
                <Image src={item.image} alt="illustration" />
              </Box>
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </Box>
  );
};

export default WhyUs;

const styles = {
  section: {
    pt: [11, null, null, 12],
    pb: [8, null, null, 9, null, 11],
  },
  tabs: {
    border: 0,
    '.rc-tabs-nav': {
      mb: [8, null, null, 9, 10, 9, 12],
    },
    '.rc-tabs-nav-wrap': {
      borderBottom: `1px solid ${rgba('#01070D', 0.1)}`,
      justifyContent: 'center',
    },
    '.rc-tabs-nav-list': {
      flexGrow: 1,
      justifyContent: 'space-evenly',
      pb: [3, null, null, 5, null, 6],
    },
    '.rc-tabs-tab-btn': {
      outline: 0,
      alignItems: 'center',
      img: {
        outline: 0,
      },
    },
    '.rc-tabs-tab': {
      backgroundColor: 'transparent',
      // m: ['0 45px'],
      h4: {
        fontFamily: 'body',
        fontSize: [0, null, null, 17, null, null, 4],
        fontWeight: 700,
        lineHeight: 1.5,
        textAlign: ['center', null, null, null, 'left'],
        whiteSpace: ['break-spaces', null, null, null, 'unset'],
      },
    },
    '.rc-tabs-tabpane': {
      display: ['flex', null, null, 'grid'],
      flexDirection: ['column-reverse', null, null, 'unset'],
      alignItems: 'center',
      justifyContent: 'center',
      gridTemplateColumns: [null, null, null, '0.9fr 1.1fr'],
      outline: 0,
      gap: [5, null, null, 11],
      h2: {
        color: 'heading',
        fontSize: [24, null, null, 6, 26, 8, 40],
        fontWeight: 700,
        lineHeight: [1.45, null, null, 1.5],
        letterSpacing: [null, null, null, '0.5px', null, '-1px'],
        textAlign: ['center', null, null, 'left'],
      },
      p: {
        color: 'textSecondary',
        fontSize: [1, null, null, 2, 17],
        lineHeight: [1.87, null, null, 2, 2.48],
        textAlign: ['center', null, null, 'left'],
        mt: [4],
      },
      '.list-item': {
        fontSize: [0, null, null, 1, 2],
        fontWeight: 500,
        lineHeight: [2.8],
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  list: {
    mt: [5],
    display: 'grid',
    justifyContent: ['center', null, null, 'unset'],
    gridTemplateColumns: ['repeat(2, 164px)', null, null, 'repeat(2, 180px)'],
  },
  learnMore: {
    mt: [4],
    textAlign: ['center', null, null, 'left'],
    a: {
      fontSize: [null, null, null, 1, 2],
    },
  },
  illustration: {
    display: ['flex'],
    alignItems: 'start',
    justifyContent: 'center',
    height: '100%',
    textAlign: [null, null, null, null, null, 'center'],
    img: {
      maxWidth: ['65%', null, null, '100%', null, '90%', '100%'],
      objectFit: 'contain',
      objectPosition: 'top'
    },
  },
};
