/** @jsx jsx */
import { jsx, Box, Container, Button } from "theme-ui";
import Masonry from "react-masonry-component";
import SectionHeading from "components/section-heading";
import BlogPost from "components/cards/blog-post";
import thumb1 from "assets/images/blog/1.png";
import thumb2 from "assets/images/blog/2.png";
import thumb3 from "assets/images/blog/3.png";
import thumb4 from "assets/images/blog/4.png";
import InnerHTML from "dangerously-set-html-content";
import { useEffect, useState } from "react";

const data = [
  {
    id: 1,
    slug: "#how-to",
    thumbnail: thumb1,
    showDescription: true,
    title: `How to work with prototype design with adobe xd featuring tools`,
    description: `The 2019 Innovation by Design Awards honor the designers and businesses solving the problems of today and tomorrow. It is one of the most sought-after design`,
  },
  {
    id: 2,
    slug: "#how-to",
    thumbnail: null,
    showDescription: false,
    title: `Anti bias receives honorable gift mention at Fast Company’s most Innovation by Design Awards`,
    description: `The 2019 Innovation by Design Awards honor the designers and businesses solving the problems of today and tomorrow. It is one of the most sought-after design`,
  },
  {
    id: 3,
    slug: "#how-to",
    thumbnail: thumb3,
    showDescription: false,
    title: `Multiple task wireframing with team management perform better`,
    description: `The 2019 Innovation by Design Awards honor the designers and businesses solving the problems of today and tomorrow. It is one of the most sought-after design`,
  },
  {
    id: 4,
    slug: "#how-to",
    thumbnail: thumb2,
    showDescription: true,
    title: `Multiple art board prototype with Figma`,
    description: `Beyond launched anti bias, a Chrome extension that replaces LinkedIn profile photos`,
  },
  {
    id: 5,
    slug: "#how-to",
    thumbnail: thumb4,
    showDescription: false,
    title: `Team presentation with latest user interface & experience reach more`,
    description: `The 2019 Innovation by Design Awards honor the designers and businesses solving the problems of today and tomorrow. It is one of the most sought-after design`,
  },
];

const masonryOptions = { originTop: true };

const mailTo = () => {
  const a = document.createElement("a");
  a.href = "mailto: sales@imakeable.com";
  document.querySelector("body").appendChild(a);
  a.click();
  document.querySelector("body").removeChild(a);
};

const htmlRender = `
<script>
  hbspt.forms.create({
    region: "na1",
    portalId: "8552561",
    formId: "652a50b0-0b9b-4a66-a947-81502a12fe18"
  });
</script>`;

const Blog = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);
  return (
    <Box as="section" id="blog" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="Let's stay in touch after SaaSTR"
          description="Focus only on your business – we’ll take care of the rest related to the development. If you think we might be able to help you in any way – feel free to contact us!"
          />
        <Box sx={styles.buttonWrapper}>
          <Button onClick={mailTo}>Contact us</Button>
        </Box>
        <Box style={{marginTop: 50}}>
            {loaded ? <InnerHTML html={htmlRender}></InnerHTML> : null}
          </Box>
        {/* <Masonry options={masonryOptions} sx={styles.postContainer}>
          {data?.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </Masonry> */}
      </Container>
    </Box>
  );
};

export default Blog;

const styles = {
  section: {
    pt: [8, null, null, 9, null, 11],
    pb: [8, null, null, 9, null, 11],
    position: "relative",
  },
  heading: {},
  buttonWrapper: {
    textAlign: ["center"],
    justifyContent: "center",
    display: "flex",
  },
};
